<template>
  <div ref="editor"></div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref, reactive, watch, inject } from "vue";
import WangEditor from "wangeditor";
import api from "../api";
import utils from "../utils/utils";

export default {
  name: "app",
  props: {
    height: {
      default: 400,
    },
    html: {},
  },
  setup(props, ctx) {
      
    const editor = ref();
    const content = reactive({
      html: "",
      text: "",
    });

    let instance;
    onMounted(() => {
      instance = new WangEditor(editor.value);
      Object.assign(instance.config, {
        onchange(html) {
          ctx.emit("change", html);
        },
      });
      instance.config.customUploadImg = async (files, editor) => {
        /* files 是 input 中选中的文件列表 */
        const file = files[0];

        files.forEach(async (item) => {
          let formData = new FormData();
          formData.append("pic", item); //存储在oss的文件路径
          const res = await api.editorUpload(formData);
          if (res.code === 0) {
            editor(utils.fileUrl(res.data));
          } else {
            inject("$message").error(res.msg);
          }
        });
      };

      instance.config.zIndex = 500;
      instance.create();
    });

    onBeforeUnmount(() => {
      instance.destroy();
      instance = null;
    });

    const syncHTML = () => {
      content.html = instance.txt.html();
    };
    const clear = () => {
      //   content.txt.clear();
      instance.txt.html("");
    };
    const data = reactive({
      status: false,
    });
    watch(
      () => props.html,
      (val, old) => {
        if (!data.status) {
          instance.txt.html(props.html);
          data.status = true;
        }
        // if (typeof props.html === 'string') {
        //   instance.txt.html(props.html);
        // }
        // instance.selection.moveCursor(instance.$textElem.elems[0], false);
        // if (!val) {
        //   instance.txt.clear();
        // }
      }
    );

    return {
      syncHTML,
      editor,
      content,
      clear,
    };
  },
};
</script>
